/* @use "variables" as *; */

.wittgenstein-400 {
  font-family: "Wittgenstein", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.wittgenstein-700 {
  font-family: "Wittgenstein Thick", serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

// <weight>: Use a value from 200 to 900
// <uniquifier>: Use a unique and descriptive class name

.noto-serif-kr-400 {
  font-family: "Noto Serif KR", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}


.noto-sans-kr-400 {
  font-family: "Noto Sans KR", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}